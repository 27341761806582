import {
  ApolloClient,
  DefaultContext,
  FetchPolicy,
  MutationFunctionOptions,
  TypedDocumentNode,
} from '@apollo/client';
import { Either, left, right } from 'fp-ts/lib/Either';
import { DocumentNode, ExecutionResult } from 'graphql';

export const createGraphqlMutationEither = async <Result, Variable>(
  mutation: (
    options?: MutationFunctionOptions<Result, Variable>
  ) => Promise<ExecutionResult<Result>>,
  options: MutationFunctionOptions<Result, Variable>
): Promise<Either<any, Result>> => {
  try {
    const { data } = await mutation(options);
    return right(data);
  } catch (err) {
    return left(err);
  }
};

export const tryQuery = async <TData>(args: {
  apolloClient: ApolloClient<any>;
  query: DocumentNode | TypedDocumentNode<TData>;
  variables?: any;
  context?: DefaultContext;
  fetchPolicy?: FetchPolicy;
}): Promise<{ response?: TData; error?: any }> => {
  try {
    const { data, error } = await args.apolloClient.query({
      query: args.query,
      variables: args.variables,
      fetchPolicy: args.fetchPolicy,
      context: args.context,
    });

    if (error) {
      return { error };
    }

    return { response: data };
  } catch (error) {
    return { error };
  }
};
